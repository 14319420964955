@font-face {
  font-family: 'ApexSerif';
  src: url('./assets/fonts/ApexSerif-Bold.woff2') format('woff2'),
  url('./assets/fonts/ApexSerif-Bold.woff') format('woff'),
  url('./assets/fonts/ApexSerif-Bold.ttf') format('truetype'),
  url('./assets/fonts/ApexSerif-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ApexSerif';
  src: url('./assets/fonts/ApexSerif-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/ApexSerif-BoldItalic.woff') format('woff'),
  url('./assets/fonts/ApexSerif-BoldItalic.ttf') format('truetype'),
  url('./assets/fonts/ApexSerif-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ApexSerif';
  src: url('./assets/fonts/ApexSerif-Book.woff2') format('woff2'),
  url('./assets/fonts/ApexSerif-Book.woff') format('woff'),
  url('./assets/fonts/ApexSerif-Book.ttf') format('truetype'),
  url('./assets/fonts/ApexSerif-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ApexSerif';
  src: url('./assets/fonts/ApexSerif-BookItalic.woff2') format('woff2'),
  url('./assets/fonts/ApexSerif-BookItalic.woff') format('woff'),
  url('./assets/fonts/ApexSerif-BookItalic.ttf') format('truetype'),
  url('./assets/fonts/ApexSerif-BookItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PoplarKozel';
  src: url('./assets/fonts/PoplarKozel.woff2') format('woff2'),
  url('./assets/fonts/PoplarKozel.woff') format('woff'),
  url('./assets/fonts/PoplarKozel.ttf') format('truetype'),
  url('./assets/fonts/PoplarKozel.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeonLight';
  src: url('./assets/fonts/neon_light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
